.plans {
  padding: 1.25rem 1.25rem 6.25rem 1.25rem;

  .plansDisabled {
    margin: 1em 4em;
    padding: 1em;
    font-size: 1.25rem;
    background-color: $gray-5;

    .col {
      text-transform: uppercase;
    }
  }

  .pubLink {
    width: 100%;
    text-align: center;
    margin-left: 0rem;
    margin-bottom: 0px !important;
    .label {
      font-weight: bold;
      color:  $gray-1;
      padding-top: 7px;
    }
    button {
      text-decoration: none !important;
    }
  }
    
  .tilesWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .switchDiv {
    margin-bottom: 20px;
    .switch {
      position: absolute;
      right: 14px;
      margin-top: -20px;
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $blue-2;
        border-color: $blue-2;
      }
    }
  
  }

  .allPlansDisclaimer {
    color: black;
    margin-left: 2rem;
    li a {
      color: $blue-2;
    }
  }
}
