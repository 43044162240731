.mainMenu {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 1rem;
  right: 0;
  left: 0;
  width: 100%;
  max-height: 106px;

  .container-fluid {
    padding: 0;
  }

  .leftMenu {
    align-self: flex-end;
    flex-grow: 1;
    max-height: 106px;
  }

  .navbar {
    padding: 5px 0 5px 0;
    flex-wrap: nowrap;
  }

  nav {
    background-color: white;
    li.nav-item {
      font-size: 1rem;
      font-family: $fontMain;
      font-weight: bold;
      padding-left: .5em;
      padding-right: .5em;
      position: relative;
      display: flex;
      align-self: center;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
      
      a {
        color: $blue-1 !important;
        display: block;
        padding: 0.5em;
        text-decoration: none;
      }
      
      a:hover {
        text-decoration: none;
        color: $blue-1;
        cursor: pointer;
      }
      
      @media screen and (max-width: 520px){
        max-width: 420px;
      }
    }

    li.nav-item > a::after  {
      bottom: 0;
      content: "";
      background-color: $blue-2;
      height: 3px;
      left: 0.5em;
      position: absolute;
      width: 0;
      transition: width .2s ease-out;
    }

    li.xsTab > a.active::after,
    li.xsTab:hover > a::after {
      width: 80% !important;
    }

    li.smTab > a.active::after,
    li.smTab:hover > a::after {
      width: 83% !important;
    }

    li.md1Tab > a.active::after,
    li.md1Tab:hover > a::after {
      width: 90% !important;
    }

    li.md2Tab > a.active::after,
    li.md2Tab:hover > a::after {
      width: 92% !important;
    }

    li.lgTab > a.active::after,
    li.lgTab:hover > a::after {
      width: 94% !important;
    }

    li.xlTab > a.active::after,
    li.xlTab:hover > a::after {
      width: 96% !important;
    }

    li.xxlTab > a.active::after,
    li.xxlTab:hover > a::after {
      width: 98% !important;
    }


    li.nav-item > a.active::after,
    li.nav-item:hover > a::after {
      width: 96%;
    }

    .planTitle a {
      font-size: 1.1rem;
    }
  }
}
