
html {
  color: $gray-1;
  line-height: 1.5;
}

body {
  font-family: $fontMain;
  font-size: $font-s;
  background-color: $gray-3 !important;
}

.nav-link:not(.active), .gridLink {
  color: $blue-2 !important;
}

.active.nav-link {
  color: #495057 !important;
}

input[type="date"] {
  max-width: 12rem;
}

.ag-group-value, .ag-group-child-count{
  font-size: 14px;
  font-weight: 500;
}
