.profile {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
}
.ManagerNotificationTittle {
    margin-left: -15px;
}
.btn-primary {
    @include button-variant($blue-2, $blue-2);
}

.weeklyInput {
    margin-left: 5px;
}