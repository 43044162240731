.login {
  background-color: $blue-1;
  width: 100%;
  height: 520px; 
}

#okta-sign-in.auth-container {
  .button-primary, .registration-link {
    color: #fff !important;
    background-color: #16709e !important;
  }
  .button-primary:hover,
  .registration-link:hover {
    background-color: #105071 !important;
    text-decoration: none;
  }

    .o-form-button-bar {
      padding: 0;
    }
}

.underMaintenance {
  .loginArea {
    filter: blur(2px);
    pointer-events: none;
  }

  .reasonArea {
    position: relative;
    z-index: 99999999;
  }

  .reasonImage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
  }
}

.terms-link {
  width: 400px;
  margin: auto;
  margin-top: -1em;
  padding-bottom: 2rem;
  font-size: 0.8em;
  padding-top: 0;
  text-align: right;
}

