.header {
  // height: $header-big-height;
  position: relative;

  background-color: $blue-1;
  background-image: url('/public/background.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center -20px;

  .headerContent {
    padding-top: 1rem;

    .media {
      display: grid;
      grid-template-columns: 300px calc(100vw - 450px) 100px;
      grid-template-rows: 60px 60px;
      grid-template-areas:
        "logo text squareLogo"
        "logo text squareLogo";

      @media screen and (max-width: 720px) {
        grid-template-columns: 200px calc(100vw - 475px) 250px;
        grid-template-rows: 60px 60px;
        grid-template-areas:
          "logo logo squareLogo"
          "text text squareLogo";
      }
    }

    .media-right {
      margin-bottom: 1.40rem;
      grid-area: squareLogo;
      justify-self: flex-end;
    }

    .media-body {
      font-size: 2rem;
      color: $gray-5;
      padding-left: 1rem;
      grid-area: text;
      padding-top: 1.5rem;
      white-space: nowrap;

      @media screen and (max-width: 720px) {
        padding-top: 0;
      }
    }
  }

  .menuContent {
    flex-grow: 1;
    padding-top: 2.5rem;
  }
  
  @media screen and (max-width: 1260px) {
    .menuContent {
        padding-top: 5rem;
    } 
  }

  @include media-breakpoint-up(md) {
    // height: $header-small-height;
    background-position: center -170px;
  }
}